<div [class.cna]="tab === 'cna' || isLoginWithCred" [class.rn]="tab === 'rn' &&  !isLoginWithCred"
     class='role-right'>
  @if (tab === 'rn' && !isLoginWithCred) {
    @if (isMobile) {
      <div class='click-to'>Click to</div>
    }
    <button (click)='loginWithPcc.emit()' type='button' [nzLoading]='isLoading'
            class='login-form__button pcc-login--btn' nz-button nzSize='large'><span nz-icon
                                                                                     nzType='icons:login-with-pcc'></span>
      <span>Sign in with<strong> PointClickCare</strong></span></button>
    <a target='_blank' (click)='handlerLoginWithCredClick()' class='login-with-cred'>log in with Aiomed
      credentials</a>
  } @else if (tab === 'cna' || isLoginWithCred) {
    <form (ngSubmit)='submitForm()' [formGroup]='loginForm' nz-form nzLayout='vertical'>
      <nz-form-item class='login-form__item' [@.disabled]='true'>
        <nz-form-label class='login-form__label'>AiOmed username</nz-form-label>
        <nz-form-control
          class='login-form__control'
          [nzErrorTip]='loginForm.errors ? incorrectNameOrPassword : usernameRequired'
          [nzValidateStatus]="loginForm.errors ? 'error' : null">
          <input (blur)='onBlur.emit()'
                 (focus)='onFocus.emit()' autocomplete='username' class='login-form__input' inputmode='email'
                 formControlName='username'
                 nz-input />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class='login-form__item' [@.disabled]='true'>
        <nz-form-label class='login-form__label'>AiOmed password</nz-form-label>
        <nz-form-control class='login-form__control' [nzValidateStatus]="loginForm.errors ? 'error' : null"
                         [nzErrorTip]='loginForm.errors ? incorrectNameOrPassword : passwordRequired'>
          <input
            [attr.data-cy]="'password-field'"
            (blur)='onBlur.emit()'
            (focus)='onFocus.emit()'
            autocomplete='off'
            [type]="passwordVisible ? 'text' : 'password'"
            class='login-form__input login-form__input-password'
            formControlName='password' nz-input type='password'
          />
          <i [attr.data-cy]="'toggle-password'" (click)='passwordVisible = !passwordVisible'
             [nzType]="passwordVisible ? 'ns:hide-password' : 'ns:show-password'"
             class='login-form__password-icon'
             nz-icon></i>
        </nz-form-control>
      </nz-form-item>
      <button [disabled]='!loginForm.valid' [nzLoading]='isLoading' type='submit'
              class='login-form__button' nz-button nzSize='large' nzType='primary'>Log in <i nz-icon
                                                                                             nzType='icons:login-arrow'></i>
      </button>
      @if (hasError) {
        <div class='login-error' [class.tablet]='isMobile'>
          <i nz-icon nzType='icons:alert-warning'></i>
          <span>Login failed. Incorrect username or password. Please check your credentials and try again</span>
        </div>
      }
    </form>
    @if (showForgotPassword) {
      <a class='forgot-password' [routerLink]="['/reset']" href='javascript:void(0);'>Forgot your password?</a>
    }
  }
</div>
<ng-template #incorrectNameOrPassword>
  <span>Incorrect <strong>Aiomed</strong> username or password</span>
</ng-template>

<ng-template #passwordRequired>
  <span class='field-required'><span nz-icon nzType='icons:alert-warning'></span> Password must be at least 8 characters!</span>
</ng-template>

<ng-template #usernameRequired>
  <span class='field-required'><span nz-icon nzType='icons:alert-warning'></span> Username is required!</span>
</ng-template>
