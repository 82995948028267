export enum WarningTitle {
	SaveIncomplete = 'PROCESS INCOMPLETE',
	Warning = 'WARNING',
	SessionExpired = 'SESSION EXPIRED',
	Offline = 'OFFLINE',
	SendingToEmr = 'SENDING DATA TO EMR'
}

export enum WarningDescription {
	SaveIncomplete = 'Required vitals missing',
	Warning = 'Exiting without saving will result in data loss',
	SessionExpired = 'Your session has timed out. You can choose to stay and reactivate the session or leave without saving',
	Offline = 'Before you leave, make sure you’ve copied the vitals. Exiting will erase the current data',
	SendingToEmr = 'The current measurement is being sent to the EMR. Please wait 60 seconds before taking a second measurement'
}

export enum WarningCancelType {
	SaveIncomplete = 'required',
	Warning = 'warning',
	SessionExpired = 'session',
	Offline = 'offline',
	SendingToEmr = 'sending-to-emr'
}
