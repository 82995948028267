import { PatientInterface } from '../../shared/model/patient'
import { ShiftType } from '../../shared/model/shift-planer.model'
import { isTakingTodayVitalsRequired } from './is-taking-today-vitals-required'

export function requiredVitals(
	patient: PatientInterface,
	currentShift: ShiftType
) {
	const requiredVitals: any = {}
	patient.treatmentPlan
		.filter(
			(tp) =>
				tp.frequency?.includes(currentShift) && isTakingTodayVitalsRequired(tp)
		)
		.forEach((tp) => {
			if (!tp.additionalInformation || !tp.additionalInformation?.length) {
				return
			}
			tp.additionalInformation?.forEach((vital) => {
				if (vital === 'BP') {
					requiredVitals['systolicPressure'] = true
					requiredVitals['diastolicPressure'] = true
				}
				if (vital === 'HR') {
					requiredVitals['heart_rate'] = true
				}
				if (vital === 'SPO2') {
					requiredVitals['spo2'] = true
				}
				if (vital === 'BT') {
					requiredVitals['body_temperature'] = true
				}
				if (vital === 'RR') {
					requiredVitals['respiration_rate'] = true
				}
				if (vital === 'BG') {
					requiredVitals['bloodGlucose'] = true
				}
			})
		})
	return requiredVitals
}
