import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import {
  AlertDTO,
  AlertResolution,
  alertSeverityComparator, alertUrgencyComparator
} from '../../shared/model/alert'
import {DataAction, StateRepository} from '@angular-ru/ngxs/decorators'
import {Actions, Selector, State, Store} from '@ngxs/store'
import {
  createEntityCollections,
  EntityDictionary
} from '@angular-ru/cdk/entity'
import {Injectable} from '@angular/core'
import {
  distinctUntilChanged,
  EMPTY,
  ignoreElements,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  takeUntil,
  tap
} from 'rxjs'
import {entitiesFilter} from '../../core/helpers/filter'
import {BackendService} from '../../shared/services/backend.service'
import {eqSet} from '../../core/helpers/functions'
import {StoreEventsService} from "../store-events.service";
import {OneToManyRelationship} from "../common";
import { PatientDTO } from '../../shared/model/patient'

export const alertFeatureName = 'alert'

@StateRepository()
@State<CollatableEntityCollections<AlertDTO>>({
  name: alertFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class AlertState extends CollatableEntityCollectionsRepository<
  AlertDTO,
  EntityCollation
> {
  subscriptionBackendUpdates$: Subscription
  subscriptionAlertsRecursively$: Subscription
  private patientIds: string[] | null = null;
  private alertUpdates$: Subscription;
  private alertStateSubscription: Subscription;

  constructor(
    private backendService: BackendService,
    private actions: Actions,
    private store: Store,
    private storeEvents: StoreEventsService
  ) {
    super()
  }

  // public get backendUpdates$(): Observable<any> {
    // this.subscriptionAlertsRecursively$ = this.backendService
    // 	.getCriticalAlertsRecursively(['open', 'snoozed'], 0)
    // 	.subscribe((res) => {
    // 		if (!res) {
    // 			this.subscriptionAlertsRecursively$.unsubscribe()
    // 			this.dispatch({ type: 'CAN ALERT_RULES LOGIN' })
    // 			return
    // 		}
    // 		this.upsertMany(
    // 			// @ts-ignore
    // 			res.data.filter((a) => {
    // 				return a.resolution !== AlertResolution.Escalated
    // 			})
    // 		)
    // 	})
    // return of();
    // return this.backendService.subscribeAllPatientsOpenCriticalAlerts().pipe(
    // 	tap((res) => {
    // 		if (res.changeType === 'removed') {
    // 			this.removeOne(res.id)
    // 			return
    // 		}
    // 		if (res.resolution === AlertResolution.Escalated) return
    // 		this.upsertOne(res)
    // 	}),
    // 	ignoreElements()
    // )
  // }

  @Selector()
  public static OpenAlerts(
    state: CollatableEntityCollections<AlertDTO>
  ): AlertDTO[] {
    return Object.values(state.entities).filter((a) => a.status === 'open')
    // 	.length
  }

  @Selector()
  public static totalCount(
    state: CollatableEntityCollections<AlertDTO>
  ): number {
    return state.totalCount
  }

  @Selector()
  public static devicesAlertsCount(
    state: CollatableEntityCollections<AlertDTO>
  ): number {
    return Object.values(state.entities).filter(
      (v: AlertDTO) => v.alertedDevice && v.status === 'open'
    ).length
  }

  @Selector()
  public static alertMuteCount(
    state: CollatableEntityCollections<AlertDTO>
  ): number {
    return Object.values(state.entities).filter(
      (v: AlertDTO) => v.status === 'snoozed'
    ).length
  }

  @Selector()
  public static alerts(
    state: CollatableEntityCollections<AlertDTO>
  ): EntityDictionary<string, AlertDTO> {
    return state.entities
  }

  @Selector()
  public static patientOpenAlerts(
    state: CollatableEntityCollections<AlertDTO>
  ): OneToManyRelationship<AlertDTO> {
    return (patientId: string) => Object.values(state.entities).filter(
      a => a.patient?.id == patientId && a.status === 'open'
    ).sort(alertUrgencyComparator)
  }

  @Selector()
  public static patientAlerts(
    state: CollatableEntityCollections<AlertDTO>
  ): OneToManyRelationship<AlertDTO> {
    return (patientId: string) => Object.values(state.entities).filter(
      a => a.patient?.id == patientId
    ).sort(alertUrgencyComparator)
  }

  @Selector()
  public static alertsDTO(
    state: CollatableEntityCollections<AlertDTO>
  ): AlertDTO[] {
    return entitiesFilter(
      state.freeTextFilter,
      Object.values(state.entities)
    ).sort((a, b) => alertSeverityComparator(a.severity, b.severity))
  }

  @Selector()
  public static alertsTotalTableCount(
    state: CollatableEntityCollections<AlertDTO>
  ): number {
    return entitiesFilter(
      state.freeTextFilter,
      Object.values(state.entities)
    ).sort((a, b) => alertSeverityComparator(a.severity, b.severity)).length
  }

  updateWithModifiedAlerts(ids?: string[]) {
    return this.backendService.findAllPatientsOpenCriticalAlerts(ids).pipe(
      tap(alerts => {
        if (alerts) {
          this.upsertMany(alerts.filter(alert => alert.resolution !== AlertResolution.Escalated));
        }
      })
    )
  }

  public override ngxsOnInit() {
    this.storeEvents.patientsModified$.pipe(
      map(patientEvent => {
        const patientIds = patientEvent.action.patients.map((patient: PatientDTO) => patient.id)
        return new Set(patientIds);
      }),
      distinctUntilChanged<Set<string>>(d => eqSet(d, new Set(this.patientIds))),
      tap((ids) => {
        if (this.alertStateSubscription) this.alertStateSubscription.unsubscribe();
        this.alertStateSubscription = this.updateWithModifiedAlerts([...ids]).subscribe();
      })
    ).subscribe();


    this.storeEvents.logout$.pipe(
      tap(() => {
        this.reset();
        this.patientIds = null;
        if (this.alertStateSubscription) this.alertStateSubscription.unsubscribe();
      })
    ).subscribe();
  }

  protected setPaginationSetting(): Observable<any> {
    return EMPTY
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    return EMPTY
  }
}
