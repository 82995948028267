import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Inject,
	NgZone,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core'
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	delay,
	EMPTY,
	filter,
	finalize,
	fromEvent,
	map,
	merge,
	Observable,
	of,
	Subscription,
	switchMap,
	take,
	tap,
	timer
} from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import LogRocket from 'logrocket'
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer'
import { Select } from '@ngxs/store'
import { ListItem } from 'design-system/lib/side-menu/side-menu.component'
import { AlertState } from '../../../../../store/alert/alert.state'
import { NavigationEnd, Router } from '@angular/router'
import { AlertDTO, AlertSeverity } from '../../../../../shared/model/alert'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { DepartmentState } from '../../../../../store/department/department.state'
import { RootStore } from '../../../../../store/root-store'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'
import { environment } from '../../../../../environments/environment'
import { PccState } from '../../../../../store/pcc/pcc.state'
import { DOCUMENT } from '@angular/common'
import { AuthState } from 'projects/aiomed/src/store/auth/auth.state'
import { DepartmentType } from 'projects/aiomed/src/shared/model/departments.model'
import { CNA_SIDEMENU_ITEMS } from '../../constants/sidemenu-items.constant'
import { DeviceState } from 'projects/aiomed/src/store/device/device.state'
import { DeviceDTO } from 'projects/aiomed/src/shared/model/device.model'
import { NewVersionCheckerService } from 'projects/aiomed/src/shared/services/new-version-checker.service'
import { ErrorState } from 'projects/aiomed/src/store/error/error.state'
import { FormBuilder, Validators } from '@angular/forms'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number'
import { adminPinCode } from '../../validators/admin-code.validator'
import { NotificationService } from 'projects/aiomed/src/shared/services/notification.service'
import { NzInputDirective } from 'ng-zorro-antd/input'
import moment from 'moment'
import { ReportState } from 'projects/aiomed/src/store/report/report.state'
import { NotificationState } from '../../../../../store/notification/notification.state'

@UntilDestroy()
@Component({
	selector: 'aiomed-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, AfterViewInit {
	@ViewChild('adminPinCodeField') adminPinCodeField: NzInputNumberComponent
	@ViewChildren(NzInputDirective) pccLoginInputs: QueryList<NzInputDirective>
	@Output('collapseMenuEmitter') collapseMenu = new EventEmitter()
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(UserState.isLoading)
	userLoading$: Observable<boolean>
	@Select(AuthState.isLoading)
	isAuthLoading$: Observable<boolean>
	@Select(RootStore.devicesAlertsCount)
	devicesAlertsCount$: Observable<number>
	@Select(RootStore.patientsAlertsCount)
	patientsAlertsCount$: Observable<number>
	@Select(NotificationState.unReadStatus)
	unReadStatusCount$: Observable<number>
	@Select(AlertState.alerts)
	alerts$: Observable<EntityDictionary<string, AlertDTO>>
	@Select(PreferenceState.preferenceSoundNotifications)
	preferenceSoundNotifications$: Observable<null | boolean>
	@Select(PreferenceState.preferenceIsCollapseMenu)
	isCollapsedMenu$: Observable<null | boolean>
	@Select(PreferenceState.hasVirtualKeyboard)
	hasVirtualKeyboard$: Observable<boolean>
	@Select(AuthState.pccLoginWasNotPerformedButClosed)
	pccLoginWasNotPerformedButClosed$: Observable<boolean>
	@Select(DepartmentState.allDepartments)
	allDepartments$: Observable<DepartmentDTO[]>
	@Select(DepartmentState.department)
	currentDepartment$: Observable<DepartmentDTO>
	@Select(DepartmentState.withoutDepartment)
	withoutDepartment$: Observable<boolean>
	@Select(DeviceState.currentUserDevice)
	currentUserDevice$: Observable<DeviceDTO>
	@Select(PreferenceState.secondaryDevicesIds)
	secondaryDevicesIds$: Observable<string[] | null>
	@Select(PreferenceState.language)
	language$: Observable<string>
	@Select(UserState.isUserCNA)
	isUserCNA$: Observable<boolean>
	@Select(UserState.isUserRN)
	isUserRN$: Observable<boolean>
	@Select(AuthState.hasCNAAccessToken)
	hasCNAAccessToken$: Observable<boolean>
	@Select(AuthState.isPccLogging)
	isPccLogging$: Observable<boolean>
	@Select(AuthState.isPCCAccountUsagePopupVisible)
	isPCCAccountUsagePopupVisible$: Observable<boolean>
	@Select(DepartmentState.departmentType)
	departmentType$: Observable<DepartmentType>
	@Select(ErrorState.errorMessage)
	errorMessage$: Observable<string | null>
	@Select(PreferenceState.incorrectTimeSystem)
	incorrectTimeSystem$: Observable<boolean>
	@Select(DeviceState.sharedDevices)
	sharedDevices$: Observable<DeviceDTO[]>
	@Select(PreferenceState.isForcedResolution)
	isForcedResolution$: Observable<boolean | undefined>
	@Select(UserState.isPccUser)
	isPccUser$: Observable<boolean>
	placement: NzDrawerPlacement = 'left'
	request: XMLHttpRequest = new XMLHttpRequest()
	context: AudioContext = new AudioContext()
	source: AudioBufferSourceNode
	timerSub: Subscription
	confirmModal?: NzModalRef
	source$: Observable<Event>
	popoverVisible: boolean = false
	reportIssueModalVisible: boolean = false
	public readonly DepartmentType = DepartmentType
	sourceClick$: Observable<Event> = fromEvent(window, 'click')
	sourceTouchmove$: Observable<Event> = fromEvent(window, 'mousemove')
	public sideMenuItems$!: Observable<ListItem[]>
	public isShowWarning: boolean = false
	public isPccTokenInvalid: boolean = false
	public isDeviceChoosing: boolean = false
	public isLogoutConfirmModalOpened: boolean = false
	public isMobile = this.preferenceState.isMobile
	public readonly currentVersionTimestamp =
		this.newVersionCheckerService.getCurrentVersionTimestamp
	public passwordVisible: boolean = false
	public selectedDeviceId: string = ''
	public selectedDepartmentId: string = ''
	public selectedSecondaryDevicesIds: string[] = []
	public hasFocus: boolean = false
	loginWithCred: boolean = false
	protected isShowDevicePairing: boolean = false
	protected departments$: Observable<DepartmentDTO[]>
	private deviceUpdateSubject = new BehaviorSubject<boolean>(false)
	readonly deviceUpdate$ = this.deviceUpdateSubject.asObservable()
	private readonly formBuilder = inject(FormBuilder)
	public adminPinCode = this.formBuilder.control('', {
		validators: [adminPinCode],
		updateOn: 'change'
	})
	public sendPCCUsageConsent = this.formBuilder.group({
		confirmPassword: this.formBuilder.control('', {
			validators: [Validators.required, Validators.minLength(6)]
		}),
		allow: this.formBuilder.control(false, {
			validators: [Validators.required]
		})
	})
	private readonly destroyRef = inject(DestroyRef)
	private readonly sessionExpirationDuration = 30

	constructor(
		private preferenceState: PreferenceState,
		public router: Router,
		public departmentState: DepartmentState,
		private pccState: PccState,
		private ngZone: NgZone,
		@Inject(DOCUMENT) private document: Document,
		private authState: AuthState,
		private readonly newVersionCheckerService: NewVersionCheckerService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private userState: UserState,
		private reportState: ReportState,
		private readonly ntfService: NotificationService
	) {}

	get allow() {
		return this.sendPCCUsageConsent.get('allow')
	}

	get confirmPassword() {
		return this.sendPCCUsageConsent.get('confirmPassword')
	}

	ngOnInit(): void {
		this.initializeListeners()
		this.setAudioContext()
		this.localStoreListener()
		this.ngZone.runOutsideAngular(() => {
			this.subscribeOnWindowsClickAndTouchmove().subscribe()
			if (this.isMobile) {
				return this.detectRnSessionExpiration().subscribe()
			} else {
				return this.detectSessionExpiration().subscribe()
			}
		})
		this.setSidenavItems()
		this.detectUserRoleChanges()
		this.setUserPasswordAfterPCCLogin()
		this.incorrectTimeSystemListener()
		this.setAllDepartments()
		if (this.isMobile) {
			this.handleTabletPccLoginFocusOut()
		}
	}

	ngAfterViewInit(): void {
		// trigger for screen saver to appear if no interactions happened
		this.document.documentElement.click()
	}

	subscribeOnWindowsClickAndTouchmove() {
		return merge(
			this.sourceClick$,
			this.sourceTouchmove$,
			this.deviceUpdate$.pipe(filter((value) => value))
		).pipe(
			switchMap((state) => {
				const timerTime = moment().add(5, 'minutes')
				return state ? timer(timerTime.toDate()) : of(null)
			}),
			tap(() => this.preferenceState.setScreenSever(true)),
			untilDestroyed(this)
		)
	}

	localStoreListener() {
		this.source$ = fromEvent(window, 'storage')
		this.source$
			.pipe(delay(500), untilDestroyed(this))
			.subscribe((data: Event) => {
				if (data instanceof StorageEvent) {
					if (
						data.key === null ||
						(data.key === 'auth.accessJwt' &&
							data.newValue === null &&
							data.oldValue != null)
					) {
						// this.systemLogOut()
					}
				}
			})
	}

	initializeListeners(): void {
		this.preferenceSoundNotifications$
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				if (!data || !environment.production) return
				this.setMediaSourceWhenHaveCriticalAlert()
			})
		this.withoutDepartment$.pipe(untilDestroyed(this)).subscribe((i) => {
			if (!i) return
			// this.handlerClickLogout()
		})
	}

	setMediaSourceWhenHaveCriticalAlert(): void {
		this.alerts$
			.pipe(untilDestroyed(this))
			.subscribe((alerts: EntityDictionary<string, AlertDTO>) => {
				const alertsDTO: AlertDTO[] = Object.values(alerts)
				const alert: AlertDTO | undefined = alertsDTO.find(
					(alert) => alert.severity === AlertSeverity.Critical
				)
				if (!alert || !alertsDTO.length) {
					if (this.timerSub) {
						this.timerSub.unsubscribe()
						this.request.abort()
					}
					return
				}
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then((stream) => {
						this.timerSub = timer(0, 50000)
							.pipe(untilDestroyed(this))
							.subscribe(() => {
								this.source.start(0)
								setTimeout(() => {
									this.source.stop(0)
									this.request.abort()
									this.setAudioContext()
								}, 2000)
							})
					})
					.catch((error) => console.log(error))
			})
	}

	setAudioContext() {
		this.source = this.context.createBufferSource()
		this.source.connect(this.context.destination)
		this.request.open('GET', 'assets/door_bell.aac', true)
		this.request.timeout = 10000
		this.request.responseType = 'arraybuffer'
		this.request.onload = () => {
			this.context.decodeAudioData(
				this.request.response,
				(response) => {
					this.source.buffer = response
					this.source.loop = true
				},
				function () {
					console.error('The request failed.')
				}
			)
		}
		this.request.send()
	}

	close(): void {
		this.collapseMenu.emit(false)
	}

	handlerChangeDepartment(departmentId: string) {
		this.preferenceState.setPreferenceDepartmentId(departmentId)
	}

	handlerClickLogout() {
		// this.pccState.pccLogout()
		// this.systemLogOut()
		this.hasFocus = false
		this.pccState
			.pccLogout()
			.pipe(
				take(1),
				catchError((er) => {
					console.error(er)
					return EMPTY
				}),
				finalize(() => {
					this.systemLogOut()
				})
			)
			.subscribe()
	}

	public openConfirmLogout(): void {
		combineLatest([this.isUserCNA$, this.isPccUser$])
			.pipe(take(1))
			.subscribe(([isUserCNA, isPccUser]) => {
				if (isUserCNA && !isPccUser) {
					this.isLogoutConfirmModalOpened = true

					setTimeout(() => {
						this.adminPinCodeField.focus()
					}, 500)
				} else {
					this.handlerClickLogout()
				}
			})
	}

	public handlerClickRnLogout(): void {
		this.pccState
			.pccLogout()
			.pipe(take(1))
			.subscribe(() => {
				this.authState.rnLogout()
				this.preferenceState.setPreferenceIsCollapseMenu(true)
			})
	}

	adminCodeFocus() {
		this.hasVirtualKeyboard$.pipe(take(2)).subscribe((hasVirtualKeyboard) => {
			if (!hasVirtualKeyboard) {
				return
			}

			this.hasFocus = true
			this.changeDetectorRef.markForCheck()
		})
	}

	adminCodeBlur() {
		setTimeout(() => {
			if (
				!this.document.activeElement ||
				this.document.activeElement.tagName !== 'INPUT'
			) {
				this.hasFocus = false
				this.changeDetectorRef.detectChanges()
			}
		}, 50)
	}

	systemLogOut() {
		this.departmentState.logout()
	}

	handlerChangeLanguageEmitter($event: string) {
		this.preferenceState.setLanguage($event)
	}

	openReportIssueModal() {
		this.reportIssueModalVisible = true
		this.popoverVisible = false
	}

	closeReportIssueModal() {
		this.reportIssueModalVisible = false
	}

	reportIssueToLogRocket(event: string) {
		this.closeReportIssueModal()
		LogRocket.captureMessage(event, {
			tags: {
				userReport: 'user-report'
			}
		})
	}

	public itemClick(): void {
		this.collapseMenu.emit(true)
	}

	public showPopover(): void {
		this.popoverVisible = true
	}

	public toggleSidenav(event: boolean): void {
		this.collapseMenu.emit(event)
	}

	public showModalLoginToPcc(): void {
		this.authState.setIsPccLogging(true)
	}

	public hideModalLoginToPcc(): void {
		this.authState.setIsPccLogging(false)
		this.hasFocus = false
		this.authState.setIsLoading(false)
		this.closeOpenedWindow()
	}

	public closeOpenedWindow(): void {
		this.authState.closeOpenedWindow()
	}

	public handlerFormSubmitEmitter(credentials: {
		username: string
		password: string
	}): void {
		this.handleRnLogin(credentials)
		this.hasFocus = false
	}

	public reLoginToRnMode(): void {
		this.authState.setIsPccLogging(true)
		this.showModalLoginToPcc()
		this.isShowWarning = false
	}

	public refreshPage(): void {
		location.reload()
	}

	public openPccLoginWindow(): void {
		this.authState.openPccLoggingWindowAfterInitialLogin(
			this.authState.getState().accessJwt?.token!
		)
		this.isPccTokenInvalid = false
	}

	public loginWithPcc(): void {
		this.authState.loginWithPcc('tablet')
	}

	public hidePCCAccountUsagePopup(): void {
		this.authState.setIsPCCAccountUsagePopupVisible(false)
	}

	public allowPCCAccountUsage(): void {
		this.authState.allowPccAccountUsage()
		this.authState.setIsPCCAccountUsagePopupVisible(false)
	}

	public pairDevices(): void {
		this.isShowDevicePairing = true
		setTimeout(() => {
			this.adminPinCodeField.focus()
		}, 500)
	}

	public hideDevicePairing(): void {
		this.isShowDevicePairing = false
		this.isDeviceChoosing = false
		this.isLogoutConfirmModalOpened = false
		this.adminPinCode.reset()
		this.adminPinCode.updateValueAndValidity()
	}

	public showDeviceChoosing(): void {
		this.isDeviceChoosing = true
		this.hasFocus = false
	}

	public showSpotlightTable(): void {
		this.reportState.getTabletSpotlightPatients()
	}

	public setCurrentDeviceAndDepartmentIds(): void {
		this.preferenceState.setCurrentDeviceId(this.selectedDeviceId)
		this.departmentState.setCurrentDepartment(this.selectedDepartmentId)
		if (this.selectedSecondaryDevicesIds) {
			this.preferenceState.setSecondaryDevicesIds(
				this.selectedSecondaryDevicesIds
			)
		}
		this.isDeviceChoosing = false
		this.hideDevicePairing()

		this.user$.pipe(take(1)).subscribe((user) => {
			if (!user?.id) {
				return
			}

			const logrocketTrackProperties = {
				name: user.name.firstName + ' ' + user.name.lastName,
				email: user.email!,
				_degree: user._degree || '',
				mainDevice: this.preferenceState.getState().deviceId || '',
				devices:
					this.preferenceState.getState().secondaryDevicesIds?.join(',') || '',
				onDutyDepartment: this.preferenceState.getState().department?.name || ''
			}
			LogRocket.identify(user?.id, logrocketTrackProperties)
			LogRocket.track('user', logrocketTrackProperties)
			LogRocket.log('user', logrocketTrackProperties)
		})
		this.collapseMenu.emit(true)
	}

	public handleEnterClick(event: KeyboardEvent): void {
		if (!this.adminPinCode.valid) {
			return
		}
		if (!this.isLogoutConfirmModalOpened) {
			this.showDeviceChoosing()
			return
		}
		this.handlerClickLogout()
	}

	handlerDeviceUpdatesEmitter() {
		this.deviceUpdateSubject.next(true)
	}

	private detectSessionExpiration() {
		return merge(this.sourceClick$, this.sourceTouchmove$).pipe(
			switchMap((state) => {
				const timerTime = moment().add(
					this.sessionExpirationDuration,
					'minutes'
				)
				return state ? timer(timerTime.toDate()) : of(null)
			}),
			tap(() => {
				this.preferenceState.setSessionExpire(true)
				this.handlerClickLogout()
			}),
			untilDestroyed(this)
		)
	}

	private detectRnSessionExpiration() {
		return merge(this.sourceClick$, this.sourceTouchmove$).pipe(
			switchMap((state) => {
				const timerTime = moment().add(
					this.sessionExpirationDuration,
					'minutes'
				)
				return state ? timer(timerTime.toDate()) : of(null)
			}),
			switchMap(() =>
				combineLatest([this.isUserRN$, this.isUserCNA$, this.isPccUser$])
			),
			switchMap(([isUserRN, isUserCNA, isPccUser]) => {
				if (isUserCNA && isPccUser) {
					this.preferenceState.setSessionExpire(true)
					this.handlerClickLogout()
					return of(null)
				}
				if (isUserRN) {
					return this.pccState.getPccIsAuthStatus()
				}
				if (this.authState.getState().CNAAccessJwt) {
					this.handlerClickRnLogout()
					this.isShowWarning = true
				}
				return of(null)
			}),
			tap((pccTokenStatus) => {
				if (pccTokenStatus !== null && !this.isShowWarning) {
					this.preferenceState.setSessionExpire(true)
					this.handlerClickLogout()
				}
			}),
			untilDestroyed(this)
		)
	}

	private setSidenavItems(): void {
		this.sideMenuItems$ = combineLatest([
			this.departmentType$,
			this.patientsAlertsCount$,
			this.devicesAlertsCount$,
			this.user$,
			this.unReadStatusCount$
		]).pipe(
			map(
				([
					departmentType,
					patientAlertsCount,
					devicesAlertsCount,
					user,
					unReadStatusCount
				]) => {
					let items = []

					if (
						user?._degree === 'MEDICAL_ASSISTANT' ||
						user?._degree === 'NO_DEGREE'
					) {
						this.navigateToVitalsIfCNA(user)
						return CNA_SIDEMENU_ITEMS
					}

					if (this.isMobile) {
						return CNA_SIDEMENU_ITEMS
					}

					if (departmentType === DepartmentType.Automatic) {
						items = [
							{
								icon: 'home',
								title: 'nav.home',
								link: '',
								badgeCount: patientAlertsCount,
								exact: true
							},
							{
								icon: 'icons:nav-shift-planer',
								title: 'Shift Planner',
								link: '/shift-planer'
							},
							{
								icon: 'aio:patients',
								title: 'nav.allPatients',
								link: '/all-patients',
								badgeCount: patientAlertsCount
							},
							{
								icon: 'aio:devices',
								title: 'nav.device',
								link: '/devices',
								badgeCount: devicesAlertsCount
							},
							{
								icon: 'aio:insights-not-filled',
								title: 'nav.insights',
								link: '/insights'
							},
							{
								icon: 'aio:alert',
								title: 'nav.alerts',
								link: '/alerts',
								badgeCount: patientAlertsCount
							},
							{ icon: 'aio:vitals', title: 'nav.vitals', link: '/reports' },
							{
								icon: 'aio:bell',
								link: '/notifications',
								isNFType: true,
								badgeCount: unReadStatusCount
							}
						]
					} else {
						items = [
							{
								icon: 'home',
								title: 'nav.home',
								link: '',
								badgeCount: patientAlertsCount,
								exact: true
							},
							{
								icon: 'icons:nav-shift-planer',
								title: 'Shift Planner',
								link: '/shift-planer'
							},
							{
								icon: 'aio:patients',
								title: 'nav.allPatients',
								link: '/all-patients',
								badgeCount: patientAlertsCount
							},
							{
								icon: 'aio:insights-not-filled',
								title: 'nav.insights',
								link: '/insights'
							},
							{ icon: 'aio:vitals', title: 'nav.vitals', link: '/reports' },
							{
								icon: 'aio:bell',
								link: '/notifications',
								isNFType: true,
								badgeCount: unReadStatusCount
							}
						]
					}

					return items
				}
			)
		)
	}

	private navigateToVitalsIfCNA(user: UserInterface | null): void {
		if (user && this.router.url !== '/reports') {
			this.router.navigate(['/reports'], { queryParamsHandling: 'merge' })
		}
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				if (user && this.router.url !== '/reports') {
					this.router.navigate(['/reports'], { queryParamsHandling: 'merge' })
				}
			})
	}

	private detectUserRoleChanges(): void {
		this.isUserCNA$
			.pipe(
				tap((isUserCNA) => {
					if (!isUserCNA) {
						this.hideModalLoginToPcc()
					}
				}),
				untilDestroyed(this)
			)
			.subscribe()
	}

	private handleRnLogin(credentials: {
		username: string
		password: string
	}): void {
		this.authState.rnLogin(credentials)
	}

	private setUserPasswordAfterPCCLogin(): void {
		this.authState.rnPasswordObservable$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((password) => {
				this.confirmPassword?.setValue(password)
				this.allow?.setValue(false)
			})
	}

	private setAllDepartments(): void {
		this.departments$ = this.allDepartments$.pipe(
			map((departments) =>
				departments.filter((department) => department.id !== 'all')
			)
		)
	}

	private incorrectTimeSystemListener() {
		this.incorrectTimeSystem$.pipe(untilDestroyed(this)).subscribe((data) => {
			if (!data) return
			this.handlerClickLogout()
		})
	}

	private handleTabletPccLoginFocusOut(): void {
		this.pccLoginWasNotPerformedButClosed$
			.pipe(untilDestroyed(this))
			.subscribe((pccLoginWasNotPerformedButClosed) => {
				if (pccLoginWasNotPerformedButClosed) {
					this.pccLoginInputs.forEach((inp) =>
						inp['elementRef'].nativeElement.blur()
					)
					this.hasFocus = false
					this.changeDetectorRef.detectChanges()
				}
			})
	}
}
