import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef
} from '@angular/core'

@Component({
	selector: 'aiomed-warning-modal',
	templateUrl: './warning-modal.component.html',
	styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
	@Output('closeModal') closeModalEmitter = new EventEmitter()
	@Output() saveModalDataEmitter = new EventEmitter()
	@Input({ transform: (value: string | null) => value || 'Warning' }) title:
		| string
		| null = 'Warning'
	@Input({
		transform: (value: string | null) =>
			value || 'Exiting without saving will result in data loss'
	})
	description: string | null = 'Exiting without saving will result in data loss'
	@Input() zIndex?: number
	@Input() closeText: string = 'Leave'
	@Input() saveText: string = 'Save & Close'
	@Input() isSessionExpired: boolean = false
	@Input() isDeleteNotification: boolean = false
	@Input() hideSave: boolean = false
	@Input() isLoading: boolean = false
	@Input() isMobile: boolean = false
	@Input() isNormalTitle: boolean = false
	@Input() width: number = 381
	@Input() descriptionTemplate: TemplateRef<unknown>
	@Input() descriptionWidth: string = 'auto'
	@Input() iconTemplate: TemplateRef<unknown>
	@Input() isSaveDisabled: boolean = false
	@Input() isFirstButtonDark: boolean = false

	constructor() {}

	ngOnInit(): void {}
}
