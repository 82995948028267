import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State, Store } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, of, Subscription } from 'rxjs'
import { AlertRuleDTO } from '../../shared/model/alert-rules.model'
import { BackendService } from '../../shared/services/backend.service'
import { tap } from 'rxjs/operators'
import { orderBy } from 'lodash-es'
import { StoreEventsService } from '../store-events.service'
import { PatientState } from '../patient/patient.state'

export const templateAlertRuleRuleFeatureName = 'templateAlertRuleRule'

@StateRepository()
@State<CollatableEntityCollections<AlertRuleDTO>>({
	name: templateAlertRuleRuleFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class TemplateAlertRuleState extends CollatableEntityCollectionsRepository<
	AlertRuleDTO,
	EntityCollation
> {
	subscriptionBackendUpdates$: Subscription
	subscriptionGetTemplateAlertRules$: Subscription

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private storeEvents: StoreEventsService,
		private store: Store
	) {
		super()
	}

	public get backendUpdates$(): Observable<void> {
		this.subscriptionGetTemplateAlertRules$ = this.backendService
			.getAllAlertRules(undefined, false)
			.subscribe((res) => {
				this.upsertMany(orderBy(res, '_creationTime', 'asc'))
				this.subscriptionGetTemplateAlertRules$.unsubscribe()
			})
		return of()
	}

	@Selector()
	public static templateAlertRules(
		state: CollatableEntityCollections<AlertRuleDTO>
	): EntityDictionary<string, AlertRuleDTO> {
		return state.entities
	}

	updateWithModifiedTemplateAlertRules() {
		return this.backendService.getTemplateAlertRulesRecursively().pipe(
			tap((res) => {
				this.upsertMany(orderBy(res, '_creationTime', 'asc'))
			})
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.patientsModified$
			.pipe(
				tap(() => {
					if (this.subscriptionBackendUpdates$)
						this.subscriptionBackendUpdates$.unsubscribe()
					this.subscriptionBackendUpdates$ =
						this.updateWithModifiedTemplateAlertRules().subscribe()
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.reset()
					if (this.subscriptionBackendUpdates$)
						this.subscriptionBackendUpdates$.unsubscribe()
				})
			)
			.subscribe()
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(): Observable<void> {
		return EMPTY
	}
}
