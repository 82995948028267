export const environment = {
	production: true,
	test: false,
	apiUrl: 'https://api.biot.aiomed.com',
	measurementsApiUrl:
		'https://production-measurement-service-ugm5hp2hqa-uk.a.run.app',
	pccIntegrationApiUrl:
		'https://production-pointclickcare-integration-ugm5hp2hqa-uk.a.run.app',
	tasksApiUrl: 'https://production-task-service-ugm5hp2hqa-uk.a.run.app',
	reportsApiUrl: 'https://production-report-service-ugm5hp2hqa-uk.a.run.app',
	authApiUrl: 'https://auth.aiomed.com',
	biobeatIntegrationApiUrl:
		'https://production-biobeat-integration-ugm5hp2hqa-uk.a.run.app',
	forceReadWsUrl:
		'wss://production-biobeat-integration-ugm5hp2hqa-uk.a.run.app',
	logrocketAppID: 'focbyk/linc',
	notificationApiUrl:
		'https://production-notifications-service-ugm5hp2hqa-uk.a.run.app',
	firebase: {
		apiKey: 'AIzaSyBou2LYIgPrU4vpNqsQSj9L5GyenDZebig',
		authDomain: 'aiomed-villa.firebaseapp.com',
		projectId: 'aiomed-villa',
		storageBucket: 'aiomed-villa.appspot.com',
		messagingSenderId: '681357787655',
		appId: '1:681357787655:web:4fa19aa13020041f2060a2',
		measurementId: 'G-FZ7VGMJSDR'
	},
	apiKey: 'AIzaSyDzIu1c0p8zCGRF-V8LktrF1vH6Kca0IZQ',
	productFruitsTabletCode: 'lYSI502tLWf7LxmS',
	productFruitsCode: 'noNgNadTs2YFvlnp',
	crispChatWebsiteID: 'f6ad5765-756a-47df-905b-f7a814c918b6'
}
