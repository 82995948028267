import { Injectable } from '@angular/core'
import { BehaviorSubject, distinctUntilChanged, shareReplay } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	private readonly isOnline$ = new BehaviorSubject<boolean>(true)
	public readonly isOnlineObs$ = this.isOnline$
		.asObservable()
		.pipe(
			distinctUntilChanged(),
			shareReplay({ bufferSize: 1, refCount: true })
		)

	public setOnline(): void {
		this.isOnline$.next(true)
	}

	public setOffline(): void {
		this.isOnline$.next(false)
	}
}
