import { NgModule } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { LoginComponent } from './components/login/login.component'
import { AuthRoutingModule } from './auth-routing.module'
import { NzFormModule } from 'ng-zorro-antd/form'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InputsModule } from '../../layouts/UI/inputs/inputs.module'
import { AuthStoreModule } from '../../../store/auth/auth-store.module'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { LoginPasswordComponent } from './components/login/login-password/login-password.component'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { LoginMfaComponent } from './components/login/login-mfa/login-mfa.component'
import { LoginSmsComponent } from './components/login/login-sms/login-sms.component'
// import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input'
import { LoginDepartmentComponent } from './components/login/login-department/login-department.component'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { ReportLoaderComponent } from '../reports/components/report-loader/report-loader.component'
import { LoginTabletComponent } from './components/login/login-tablet/login-tablet.component'
import { InformationModalComponent } from '../../layouts/modals/components/information-modal/information-modal.component'
import { LoginTabletNurseComponent } from './components/login/login-tablet-nurse/login-tablet-nurse.component'

@NgModule({
	declarations: [
		LoginComponent,
		LoginPasswordComponent,
		LoginMfaComponent,
		LoginSmsComponent,
		LoginDepartmentComponent,
		LoginTabletComponent
	],
	imports: [
		CommonModule,
		AuthStoreModule,
		AuthRoutingModule,
		NzFormModule,
		ReactiveFormsModule,
		InputsModule,
		NzTabsModule,
		NzInputModule,
		NzIconModule,
		NzButtonModule,
		// NgxIntlTelephoneInputModule,
		NzSelectModule,
		FormsModule,
		NzCheckboxModule,
		ReportLoaderComponent,
		InformationModalComponent,
		LoginTabletNurseComponent,
		NgOptimizedImage
	],
	exports: [LoginPasswordComponent]
})
export class AuthModule {}
