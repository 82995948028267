import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	BehaviorSubject,
	combineLatest,
	EMPTY,
	Observable,
	Subscription,
	tap
} from 'rxjs'
import {
	InsightDTO,
	InsightsAllActiveTotalCount,
	InsightsBackend
} from '../../shared/model/insight.model'
import { BackendService } from '../../shared/services/backend.service'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { PatientDTO, PatientInterface } from '../../shared/model/patient'
import { FileDTO } from '../../shared/model/file'
import moment from 'moment/moment'
import { StoreEventsService } from '../store-events.service'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { DepartmentState } from '../department/department.state'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { orderBy } from 'lodash-es'
import { entitiesFilter } from '../../core/helpers/filter'

export const insightFeatureName = 'insightBrowsing'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
	name: insightFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class InsightBrowsingState extends CollatableEntityCollectionsRepository<
	InsightDTO,
	EntityCollation
> {
	private subscriptionGetAllInsights$: Subscription
	private insightsAllActiveTotalCount$ =
		new BehaviorSubject<InsightsAllActiveTotalCount>({
			activeTotalCount: 0,
			allTotalCount: 0
		})
	public readonly insightsAllActiveTotalCountObs$ =
		this.insightsAllActiveTotalCount$.asObservable()

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private patientState: PatientState,
		private storeEvents: StoreEventsService,
		private departmentState: DepartmentState
	) {
		super()
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<InsightDTO>
	): boolean {
		return state.isLoading
	}

	@Selector([PatientState.patient])
	public static currentInsight(
		state: CollatableEntityCollections<InsightDTO>,
		patient: PatientInterface | null
	): InsightDTO | null {
		if (state.currentInsights && patient) {
			return { ...state.currentInsights, patient }
		}
		return null
	}

	@Selector([
		PatientState.entities,
		FileState.files,
		DepartmentState.department
	])
	public static insights(
		state: CollatableEntityCollections<InsightDTO>,
		patient: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO | undefined
	): InsightDTO[] {
		let patients = Object.values(patient)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentInsights: InsightDTO[] = []
		Object.values(state.entities).forEach((insight) => {
			if (insight.patient && insight.patient.id) {
				const patient: PatientDTO | undefined = Object.values(patients).find(
					(p) => p.id === insight.patient?.id
				)
				if (patient) {
					currentInsights.push({
						...insight,
						patient: {
							...patient,
							// @ts-ignore
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			}
		})
		return currentInsights
	}

	@Selector([
		PatientState.entities,
		FileState.files,
		DepartmentState.department
	])
	public static activeInsights(
		state: CollatableEntityCollections<InsightDTO>,
		patient: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO | undefined
	): InsightDTO[] {
		let patients = Object.values(patient)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentInsights: InsightDTO[] = []
		Object.values(state.entities).forEach((insight) => {
			if (insight.patient && insight.patient.id) {
				const patient: PatientDTO | undefined = Object.values(patients).find(
					(p) => p.id === insight.patient?.id
				)
				if (patient) {
					currentInsights.push({
						...insight,
						patient: {
							...patient,
							// @ts-ignore
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			}
		})
		return currentInsights.filter((insight) => !insight.insightEndTime)
	}

	@Selector()
	public static insightsRelatedType(
		state: CollatableEntityCollections<InsightDTO>
	): string {
		let insightsRelatedType = ''
		Object.values(state.entities)
			.filter(
				(i) =>
					moment(new Date()).diff(moment(i.insightStartTime), 'hours') <= 48
			)
			.forEach((i) => {
				if (!insightsRelatedType) {
					insightsRelatedType = i.insightSubject.split('_').join(' ')
				} else {
					insightsRelatedType = `${insightsRelatedType}, ${i.insightSubject
						.split('_')
						.join(' ')}`
				}
			})
		return insightsRelatedType
	}

	@Selector()
	public static totalCount(
		state: CollatableEntityCollections<InsightDTO>
	): number {
		return state.totalCount
	}

	public override ngxsOnInit() {
		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.reset()
				})
			)
			.subscribe()
	}

	@DataAction()
	public loadPatientInsights(
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch: string,
		@Payload('patientId') patientId?: string,
		@Payload('needPatientsIds') needPatientsIds: boolean | undefined = false,
		@Payload('status') status?: string
	) {
		this.ctx.patchState({
			isLoading: true
		})
		return this.backendService
			.getBrowsingInsights(
				page,
				freeTextSearch,
				patientId,
				needPatientsIds
					? this.searchInsightPatientByNameAndRoom(freeTextSearch).map(
							(p) => p.id
					  )
					: undefined,
				status
			)
			.pipe(
				tap((res: InsightsBackend) => {
					const insights = Object.values(this.getState().entities)
					this.removeAll()
					if (
						needPatientsIds &&
						!this.searchInsightPatientByNameAndRoom(freeTextSearch).map(
							(p) => p.id
						).length &&
						freeTextSearch.length
					) {
						this.upsertMany([])
					} else {
						this.upsertMany(
							page === 0
								? orderBy([...res.data], 'insightStartTime', 'desc')
								: orderBy(
										[...insights, ...res.data],
										'insightStartTime',
										'desc'
								  )
						)
					}
					this.ctx.patchState({ isLoading: false })
					if (freeTextSearch) return
					this.patchState({
						totalCount: res.metadata.page?.totalResults
					})
				})
			)
	}

	searchInsightPatientByNameAndRoom(freeTextSearch: string): PatientDTO[] {
		const department =
			this.departmentState.snapshot.currentDepartment ||
			this.departmentState.entities[0]
		let patients = Object.values(this.patientState.entities)
		if (department && department.id !== DepartmentFilter.All) {
			patients = entitiesFilter(
				freeTextSearch,
				patients.filter(
					(patient) =>
						patient.department && patient.department.id === department.id
				)
			)
		} else {
			patients = entitiesFilter(
				freeTextSearch,
				patients.filter((patient) => patient.department)
			)
		}
		return patients
	}

	@DataAction()
	public getPatientInsightsTotalResult() {
		const ids: string[] = this.searchInsightPatientByNameAndRoom('').map(
			(p) => p.id
		)
		return combineLatest(
			this.backendService.getBrowsingCombineInsights(0, ids),
			this.backendService.getBrowsingInsights(0, '', undefined, ids, undefined)
		).pipe(
			tap(([active, all]) =>
				this.insightsAllActiveTotalCount$.next({
					allTotalCount: all?.metadata?.page?.totalResults || 0,
					activeTotalCount: active?.metadata?.page?.totalResults || 0
				})
			)
		)
	}

	clearPatientInsightsTotalResult() {
		this.insightsAllActiveTotalCount$.next({
			allTotalCount: 0,
			activeTotalCount: 0
		})
	}

	@DataAction()
	public loadPatientCombineInsights(
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch: string
	) {
		this.ctx.patchState({
			isLoading: true
		})
		const ids: string[] = this.searchInsightPatientByNameAndRoom(
			freeTextSearch
		).map((p) => p.id)
		return this.backendService
			.getBrowsingCombineInsights(
				page,
				this.searchInsightPatientByNameAndRoom(freeTextSearch).map((p) => p.id)
			)
			.pipe(
				tap((res: InsightsBackend) => {
					const insights = Object.values(this.getState().entities)
					this.removeAll()
					if (!ids.length && freeTextSearch.length) {
						this.upsertMany([])
					} else {
						this.upsertMany(
							page === 0
								? orderBy(
										[...res.data],
										[
											(item) => (item.insightStatus === 'ongoing' ? 0 : 1),
											'insightStartTime'
										],
										['asc', 'desc']
								  )
								: orderBy(
										[...insights, ...res.data],
										[
											(item) => (item.insightStatus === 'ongoing' ? 0 : 1),
											'insightStartTime'
										],
										['asc', 'desc']
								  )
						)
					}
					this.ctx.patchState({ isLoading: false })
					if (freeTextSearch) return
					this.patchState({
						totalCount: res.metadata.page?.totalResults
					})
				})
			)
	}

	@DataAction()
	public loadPatientInsightsForThreeMonth(
		@Payload('patientId') patientId: string,
		@Payload('insightId') insightId: string,
		@Payload('subject') subject: string,
		@Payload('outlineType') outlineType: string
	) {
		this.setMany([])
		return (this.subscriptionGetAllInsights$ = this.backendService
			.getAllInsightsBrowsingRecursively(0, patientId)
			.subscribe((res) => {
				if (!res) {
					this.subscriptionGetAllInsights$.unsubscribe()
					return
				}
				this.setMany(
					res.data.filter(
						(i) =>
							i.id !== insightId &&
							i.insightSubject === subject &&
							i.outlineType === outlineType
					)
				)
			}))
	}

	@DataAction()
	public getPatientInsight(@Payload('id') id: string) {
		this.ctx.patchState({
			isLoading: true
		})
		return this.backendService.getBrowsingInsight(id).pipe(
			tap((res: InsightDTO[]) => {
				const currentInsights = res.find((insight) => insight.id === id)
				if (currentInsights) {
					this.patientState.focusOnPatient(currentInsights.patient.id)
					this.patchState({
						currentInsights
					})
				}
			})
		)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
