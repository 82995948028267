<section [attr.data-cy]="'aiomed-warning-modal'" [class.mobile]='isMobile' [style.--modal-width]='width' [style.--zindex]='zIndex' class='warning-modal'>
  <div [class.container-session-desktop]='isSessionExpired || isDeleteNotification' class='warning-modal__container'>
    <div class='warning-modal__title-box'>
      <ng-container [ngTemplateOutlet]='iconTemplate || defaultIconTemplate'></ng-container>
      <ng-template #defaultIconTemplate>
        <i [class.icon-session-desktop]='isSessionExpired || isDeleteNotification' nz-icon nzType='icons:warning'></i>
      </ng-template>
      <div [class.normal]='isNormalTitle' [class.title-session-desktop]='isSessionExpired || isDeleteNotification'
           class='title'>{{ title }}
      </div>
    </div>
    <div [class.text-delete-notification]='isDeleteNotification' [class.text-session-desktop]='isSessionExpired'
         [style.--description-width]="descriptionWidth" class='text'>
      <ng-container [ngTemplateOutlet]='descriptionTemplate || defaultTemplate'></ng-container>
      <ng-template #defaultTemplate>
        {{ description }}
      </ng-template>
    </div>
    <div [class.button-box-session-desktop]='isSessionExpired || isDeleteNotification'
         class='warning-modal__button-box'>
      <button [attr.data-cy]="'warning-close'" (click)='closeModalEmitter.emit(false)'
              [class.dark-btn]='hideSave || isFirstButtonDark'>{{ closeText }}
      </button>
      @if (!hideSave) {
        <button [attr.data-cy]="'warning-save'" class="save" [attr.aria-disabled]='isSaveDisabled' (click)='isSaveDisabled ? $event.preventDefault(): saveModalDataEmitter.emit(true)'
                [nzLoading]='isLoading' nz-button
                nzType='primary'>{{ saveText }}
        </button>
      }
    </div>
  </div>
</section>
