import { DepartmentFilter } from '../../shared/model/departments.model'

export interface IRedirectData {
	hasData: boolean
	redirectUrl?: string
	department?: string
}

export interface IRedirectDataQueryParams {
	hasData: boolean
	queryParams?: {
		redirectUrl: string
		unit: string
	}
}

export const checkRedirectData = (
	logic: 'notifications' = 'notifications'
): boolean => {
	const urlParams = new URLSearchParams(window.location.search)
	if (logic === 'notifications') {
		const rUrl = urlParams.get('redirectUrl')
		const d = urlParams.get('unit')
		return !!(d && rUrl && d === DepartmentFilter.All)
	}
	return false
}

export const getRedirectData = (
	logic: 'notifications' = 'notifications'
): IRedirectData => {
	const urlParams = new URLSearchParams(window.location.search)
	if (logic === 'notifications' && checkRedirectData()) {
		return {
			hasData: true,
			redirectUrl: urlParams.get('redirectUrl') as string,
			department: urlParams.get('unit') as string
		}
	}
	return {
		hasData: false
	}
}

export const seRedirectDataToLocalStorage = (
	logic: 'notifications' = 'notifications'
) => {
	const urlParams = new URLSearchParams(window.location.search)
	if (logic === 'notifications') {
		const rUrl = urlParams.get('redirectUrl')
		const d = urlParams.get('unit')
		if (d && rUrl && d === DepartmentFilter.All) {
			localStorage.setItem('rl', urlParams.toString())
		}
	}
}

export const getRedirectDataToLocalStorageTransformToQueryParams = (
	logic: 'notifications' = 'notifications'
): IRedirectDataQueryParams => {
	if (logic === 'notifications') {
		const queryString = localStorage.getItem('rl')
		if (!queryString) {
			return {
				hasData: false
			}
		}
		const urlParams = new URLSearchParams(queryString as string)
		const queryParams: any = {}
		urlParams.forEach((value, key) => {
			;(queryParams as any)[key] = value
		})
		localStorage.removeItem('rl')
		return { hasData: true, queryParams }
	}
	return {
		hasData: false
	}
}
