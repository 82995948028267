import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthState } from '../../store/auth/auth.state'
import { PreferenceState } from '../../store/preference/preference.state'

@Injectable({
	providedIn: 'root'
})
export class NotAuthenticated {
	constructor(
		private router: Router,
		private authState: AuthState,
		private preferenceState: PreferenceState
	) {}

	canActivate() {
		return this.activate()
	}

	canLoad() {
		return this.activate()
	}

	private activate() {
		if (
			this.authState.isAuthenticated() &&
			!this.preferenceState.isMfaRequired() &&
			!this.preferenceState.needChooseDepartment()
		) {
			this.router.navigateByUrl('/')
			return false
		} else {
			return true
		}
	}
}
