import {Injectable} from "@angular/core";
import {ActionContext, Actions, ActionStatus, getActionTypeFromInstance} from "@ngxs/store";
import {filter, share } from "rxjs/operators";

@Injectable()
export class StoreEventsService {
  constructor(
    private actions: Actions) {
  }

  public userModified$ = this.actions
    .pipe(
      filter(ac => StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@user.getCurrentUser()")
      ),
    );

  public devicesModified$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@device.upsertMany(entities)")
      )
    );

  public shiftChanged$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@shiftPlaner.shiftChanged()")
      )
    );

  public patientsModified$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.actionType(ac) === "PATIENTS_MODIFIED"
      ),
      share()
    );

  public homePageNavigated$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.actionType(ac) === 'HOME PAGE NAVIGATED'
      ),
      share()
    );

  public departmentModifiedAndRefreshToken$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && ( StoreEventsService.actionType(ac) === "DEPARTMENT UPDATE" || StoreEventsService.actionType(ac) === "REFRESH TOKEN")
      )
    );

  public alertModified$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@alert.upsertMany(entities)")
      )
    );
  public departmentModified$ = this.actions.pipe(
    filter(
      (ac) =>
        StoreEventsService.isSuccessful(ac) &&
        StoreEventsService.actionType(ac) === 'DEPARTMENT UPDATE'
    )
  )
  public reportModified$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && (StoreEventsService.isActionTypePrefix(ac, "@report.getReportsData()") || StoreEventsService.isActionTypePrefix(ac, "@report.getReportsData($arg0)"))
      )
    );

  public loggedIn$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.actionType(ac) === 'LOGGED IN'
      ),
      share()
    );

  public departmentChange$ = this.actions
  .pipe(
    filter(ac =>
      StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@department.departmentChange(department)")
    ),
    share()
  );

  public lostMqttConnection$ = this.actions
  .pipe(
    filter(ac =>
      StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@measurement.lostMqttConnection()")
    ),
    share()
  );

  public establishMqttConnection$ = this.actions
  .pipe(
    filter(ac =>
      StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@measurement.establishMqttConnection()")
    ),
    share()
  );

  public patientsModifiedOrDepartmentChange$ = this.actions
  .pipe(
    filter(ac =>
      StoreEventsService.isSuccessful(ac) && (StoreEventsService.isActionTypePrefix(ac, "@department.departmentChange(department)") || StoreEventsService.isActionTypePrefix(ac, "@patient.upsertMany(entities)"))
    ),
    share()
  );

  public loggedInAndRefreshToken$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && (StoreEventsService.actionType(ac) === 'LOGGED IN' || StoreEventsService.actionType(ac) === "REFRESH TOKEN")
      ),
      share()
    );

  public logout$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@auth.logout()")
      ),
      share()
    );

  public login$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@auth.login(req)")
      ),
      share()
    );

  public loginWithPccIntoSystemTablet$ = this.actions
    .pipe(
      filter(ac =>
        StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@auth.loginWithPccIntoSystemTablet()")
      ),
      share()
    );

  public canLoadTabletSpotlightPatients$ = this.actions.pipe(
    filter(ac => StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@user.canLoadTabletSpotlightPatients()"))
  )

  public getTabletSpotlightPatients$ = this.actions.pipe(
    filter(ac => StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@report.getTabletSpotlightPatients()"))
  )

  public closeTabletSpotlightPatients$ = this.actions.pipe(
    filter(ac => StoreEventsService.isSuccessful(ac) && StoreEventsService.isActionTypePrefix(ac, "@report.closeTabletSpotlightPatients()"))
  )


  public static isSuccessful = (actx: ActionContext<any>) => actx.status == ActionStatus.Successful;
  public static actionType = (actx: ActionContext<any>): string | undefined => getActionTypeFromInstance(actx.action);
  public static isActionTypePrefix = (actx: ActionContext<any>, prefix: string): boolean => StoreEventsService.actionType(actx)?.startsWith(prefix) ?? false

}
