import { PatientInterface } from '../../shared/model/patient'
import { ShiftType } from '../../shared/model/shift-planer.model'
import { isTakingTodayVitalsRequired } from './is-taking-today-vitals-required'

export function isAnyVitalsMissing(
	patient: PatientInterface,
	currentShift: ShiftType
): boolean {
	let isAnyVitalsMissing = false
	patient.treatmentPlan
		.filter(
			(tp) =>
				tp.frequency?.includes(currentShift) && isTakingTodayVitalsRequired(tp)
		)
		.some((tp) => {
			if (!tp.additionalInformation || !tp.additionalInformation?.length) {
				return false
			}
			tp.additionalInformation?.forEach((vital) => {
				if (
					(vital === 'HR' &&
						!patient?.measurement?.latestPerVital?.heart_rate) ||
					(vital === 'SPO2' && !patient?.measurement?.latestPerVital?.spo2) ||
					(vital === 'BG' &&
						!patient?.measurement?.latestPerVital?.bloodGlucose) ||
					(vital === 'BT' &&
						!patient?.measurement?.latestPerVital?.body_temperature) ||
					(vital === 'RR' &&
						!patient?.measurement?.latestPerVital?.respiration_rate) ||
					(vital === 'BP' &&
						!(
							patient?.measurement?.latestPerVital?.systolicPressure &&
							patient?.measurement?.latestPerVital?.diastolicPressure
						))
				) {
					isAnyVitalsMissing = true
				}
			})
			return isAnyVitalsMissing
		})
	return isAnyVitalsMissing
}
