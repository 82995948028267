import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnDestroy,
	Output,
	TemplateRef
} from '@angular/core'
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { Subject, takeUntil } from 'rxjs'
import { BreakpointObserver, LayoutModule } from '@angular/cdk/layout'
import { TranslateModule } from '@ngx-translate/core'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { AvatarComponent } from '../avatar/avatar.component'

export interface BaseUser {
	name: string | { firstName: string; lastName: string }
	avatar?: string
	signedUrl?: string
}

export interface ListItem {
	link: string
	icon?: string
	title?: string
	iconTheme?: string
	badgeCount?: number
	exact?: boolean
	isNFType?: boolean
}

@Component({
	selector: 'lib-side-menu',
	standalone: true,
	imports: [
		NgFor,
		NgIf,
		NzIconModule,
		NzBadgeModule,
		AvatarComponent,
		RouterLink,
		RouterLinkActive,
		LayoutModule,
		NzPopoverModule,
		TranslateModule,
		NgTemplateOutlet
	],
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements AfterViewInit, OnDestroy {
	@Output() logout: EventEmitter<void> = new EventEmitter<void>()
	@Output() avatarClick: EventEmitter<void> = new EventEmitter<void>()
	@Output() listItemClick: EventEmitter<{ item: ListItem; index: number }> =
		new EventEmitter<{
			item: ListItem
			index: number
		}>()
	@Output() isOpenedMenuChange: EventEmitter<boolean> =
		new EventEmitter<boolean>()
	@Input({ required: true }) nzPopoverContent!: TemplateRef<any>
	@Input({ required: true }) popoverVisible!: boolean
	@Input() logoutHidden: boolean = false
	@Input({ required: false }) customTemplateContent!: TemplateRef<any>
	@Input({ required: true }) items: ListItem[]
	@Input() logoutText: string = 'Log out'
	@Input() departmentName: string | null = null
	@Input() deviceName: string | null = null
	@Input() isUserRN: boolean = false
	@Input({
		required: true,
		transform: (value: BaseUser) => {
			if (typeof value?.name === 'string') return value
			return {
				...value,
				name:
					(value?.name?.firstName ?? '') + ' ' + (value?.name?.lastName ?? ''),
				avatar: value?.signedUrl
			}
		}
	})
	user: BaseUser
	@Input() isOpened: boolean = false
	@Input() isMobile: boolean | undefined = undefined
	public isTabletView: boolean
	private destroy$ = new Subject<void>()
	private cdr = inject(ChangeDetectorRef)
	private breakpointObserver = inject(BreakpointObserver)

	get getNotificationItem() {
		return this.items[this.items.length - 1]
	}

	ngAfterViewInit(): void {
		this.breakpointObserver
			.observe([
				'(max-width:1024px) and (orientation: portrait)',
				'(max-width:1280px) and (orientation: landscape)'
			])
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				this.isOpenedMenuChange.emit(true)
				if (this.isMobile !== undefined) {
					this.isTabletView = this.isMobile
					return
				}
				if (result.matches) {
					this.isTabletView = true
				} else {
					this.isTabletView = false
				}
				this.cdr.detectChanges()
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	public onListItemClick(item: ListItem, index: number): void {
		this.listItemClick.emit({ item, index })
	}

	@HostListener('window:click', ['$event'])
	clickOut(event: Event): void {
		const target = event.target as HTMLElement
		if (
			target.classList.contains('sidemenu') &&
			target.classList.contains('opened')
		) {
			this.isOpenedMenuChange.emit(true)
		}
	}
}
