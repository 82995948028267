import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import {
	combineLatest,
	EMPTY,
	ignoreElements,
	Observable,
	of,
	Subscription,
	tap,
	throwError
} from 'rxjs'
import { UserStateInterface } from './types/userState.interface'
import { FileState } from '../file/file.state'
import { UserDTO, UserInterface } from '../../shared/model/user.model'
import { AuthState } from '../auth/auth.state'
import { BackendService } from '../../shared/services/backend.service'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { FileDTO } from '../../shared/model/file'
import { catchError, retry, take } from 'rxjs/operators'
import LogRocket from 'logrocket'
import { StoreEventsService } from '../store-events.service'
import { productFruits } from 'product-fruits'
import { environment } from '../../environments/environment'
import { NzMessageService } from 'ng-zorro-antd/message'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Crisp } from 'crisp-sdk-web'
import { NewVersionCheckerService } from '../../shared/services/new-version-checker.service'
import { PreferenceState } from '../preference/preference.state'
import { CNA_STORAGE_KEY } from '../../shared/constants/cna-storage-key.constant'
import { isBeforeCurrentShift } from '../../core/helpers/check-fresh-vital-current-shift'
import moment from 'moment'

export const userFeatureName = 'user'

@StateRepository()
@State<UserStateInterface>({
	name: userFeatureName,
	defaults: {
		user: null,
		isLoading: false,
		messagingToken: null,
		userProfileId: null,
		medicalAssistants: [],
		beforeLogIntUser: null,
		allUsersProfile: []
	}
})
@Injectable()
export class UserState extends NgxsDataRepository<UserStateInterface> {
	private userStateSubscription: Subscription
	private isMobile = this.preferenceState.isMobile
	private readonly isUserCNAKey = CNA_STORAGE_KEY

	constructor(
		private backendService: BackendService,
		private fileState: FileState,
		private authState: AuthState,
		private actions: Actions,
		private storeEvents: StoreEventsService,
		private store: Store,
		private message: NzMessageService,
		private deviceService: DeviceDetectorService,
		private readonly newVersionCheckerService: NewVersionCheckerService,
		private readonly preferenceState: PreferenceState
	) {
		super()
	}

	@Selector([FileState.files])
	static currentUser(
		state: UserStateInterface,
		files: any
	): UserInterface | null {
		const user = {
			...state.user
		}
		if (
			!state.user ||
			!state.user.avatar ||
			!Object.values(files).length ||
			!files[state.user?.avatar?.id]
		) {
			return state.user
		}
		if (state.user.avatar?.id && files[state.user.avatar?.id]) {
			user.signedUrl = files[state.user.avatar.id]?.signedUrl
		}
		return {
			...user
		} as UserInterface
	}

	@Selector()
	static isPccUser(state: UserStateInterface): boolean {
		if (!state || !state.user || !state.user.email) {
			return false
		}
		return state.user.email.includes('pcc')
	}

	@Selector()
	static beforeLogIntUser(state: UserStateInterface): UserInterface | null {
		return state.beforeLogIntUser
	}

	@Selector()
	static userProfile(state: UserStateInterface): any {
		return state.userProfileId
	}

	@Selector()
	static allUsersProfileLength(state: UserStateInterface): any {
		return state.allUsersProfile.length
	}

	@Selector()
	static isLoading(state: UserStateInterface): boolean {
		return state.isLoading
	}

	@Selector([FileState.files])
	static medicalAssistants(
		state: UserStateInterface,
		files: EntityDictionary<string, FileDTO>
	): UserInterface[] | null {
		return !state.medicalAssistants
			? null
			: state.medicalAssistants.map((caregiver) => ({
					...caregiver,
					avatar: caregiver?.avatar?.id ? files[caregiver.avatar.id] : null
			  }))
	}

	@Selector()
	static isUserCNA(state: UserStateInterface): boolean {
		return (
			state.user?._degree === 'MEDICAL_ASSISTANT' ||
			state.user?._degree === 'NO_DEGREE'
		)
	}

	@Selector([AuthState.hasCNAAccessToken])
	static isUserRN(
		state?: UserStateInterface,
		hasCNAAccessToken?: boolean
	): boolean {
		return (
			(state?.user?._degree === 'MEDICAL_DOCTOR' ||
				state?.user?._degree === 'REGISTERED_NURSE') &&
			!hasCNAAccessToken
		)
	}

	@Selector()
	static isUser(state: UserStateInterface): boolean {
		return !!state.user
	}

	public haveCurrentUser(): boolean {
		return !!this.snapshot.user
	}

	public override ngxsOnInit() {
		this.storeEvents.loggedInAndRefreshToken$
			.pipe(
				tap(() => {
					if (this.userStateSubscription)
						this.userStateSubscription.unsubscribe()
					this.userStateSubscription = combineLatest([
						this.getCurrentUser(),
						this.getCaregivers()
					])
						.pipe(
							catchError((err) => {
								this.patchState({ isLoading: false })
								console.error(err)
								return of(err)
							})
						)
						.subscribe()
				})
			)
			.subscribe()

		this.storeEvents.shiftChanged$
			.pipe(
				tap(() => {
					if (this.store.selectSnapshot(UserState.isUserCNA)) {
						LogRocket.startNewSession()
					}
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.patchState({
						user: null,
						isLoading: false,
						medicalAssistants: []
					})
					this.reset()
					localStorage.removeItem(this.isUserCNAKey)
					if (this.userStateSubscription)
						this.userStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	// @DataAction({ subscribeRequired: false })
	public getCaregivers(): Observable<UserDTO[] | null> {
		if (!this.authState.isAuthenticated()) {
			this.patchState({ medicalAssistants: [] })
			return of(null)
		}
		return this.backendService.getAllCaregivers().pipe(
			tap((medicalAssistants) => {
				if (!medicalAssistants) return
				this.patchState({ medicalAssistants })
				const data = medicalAssistants
					.map((patient) => patient.avatar?.id)
					.filter((i: string | any) => i)
				if (data.length && data.length > 0) {
					data.forEach((id: string) => this.fileState.requestAvatar(id))
				}
			})
		)
	}

	// @DataAction({ subscribeRequired: false })
	public getBeforeAuthUser(): Observable<UserDTO | null> {
		return this.backendService.getUserSelf().pipe(
			tap((beforeLogIntUser) => {
				this.patchState({
					beforeLogIntUser
				})
			}),
			catchError((err) => {
				this.authState.logout()
				return throwError(() => err)
			})
		)
	}

	// @DataAction({ subscribeRequired: false })
	public getCurrentUser(): Observable<UserDTO | null> {
		this.patchState({
			isLoading: true
		})
		if (!this.authState.isAuthenticated()) {
			this.patchState({ user: null, isLoading: false })
			return of(null)
		}
		return this.backendService.getUserSelf().pipe(
			tap((user) => {
				const isUserChanged =
					this.getState().user && user?.id !== this.getState().user?.id

				if (user.avatar && user.avatar.id) {
					this.fileState.requestAvatar(user.avatar.id)
				}
				this.loadUserProfile(user)
				this.patchState({
					user,
					isLoading: false
				})

				this.loadAllUsersProfile()
				const isAiomedCNA =
					(user._degree === 'MEDICAL_ASSISTANT' ||
						user._degree === 'NO_DEGREE') &&
					!user.email?.includes('pcc')
				localStorage.setItem(this.isUserCNAKey, isAiomedCNA + '')

				if (isUserChanged) {
					LogRocket.startNewSession()
				}

				if (isUserChanged || isUserChanged === null) {
					const logrocketTrackProperties = {
						name: user.name.firstName + ' ' + user.name.lastName,
						email: user.email!,
						locale: user.locale || '',
						gender: user.gender || '',
						dateOfBirth: user.dateOfBirth || '',
						address: JSON.stringify(user.address) || '',
						onDutyDepartment:
							this.preferenceState.getState().department?.name || '',
						_degree: user._degree || '',
						_ownerOrganization: JSON.stringify(user._ownerOrganization),
						version: this.newVersionCheckerService.getCurrentVersionTimestamp(),
						mainDevice: this.preferenceState.getState().deviceId || '',
						devices:
							this.preferenceState.getState().secondaryDevicesIds?.join(',') ||
							''
					}

					LogRocket.identify(user.id, logrocketTrackProperties)
					LogRocket.track('user', logrocketTrackProperties)
				}

				Crisp.user.setEmail(user.email!)
				Crisp.user.setNickname(user.name.firstName + ' ' + user.name.lastName)
				this.initProductFruits(user)
				this.dispatch({ type: '@user.getCurrentUser()' })
			}),
			retry({ count: 3, delay: 2000 }),
			catchError((er) => {
				this.patchState({ isLoading: false })
				this.message.error('Failed to load User. Please, reload page.')
				return of(er)
			})
		)
	}

	// @DataAction()
	// public updateUser(@Payload('data') data: UserDTO): Observable<void> {
	// 	// @ts-ignore
	// 	// e47a2373-fc97-4497-a0ad-991bca99be64
	// 	return (
	// 		this.caregiverAPIService
	// 			// @ts-ignore
	// 			.updateCaregiver('e47a2373-fc97-4497-a0ad-991bca99be64', data)
	// 			.pipe(
	// 				tap((res: CaregiverResponse) => {
	// 					const user = UserState.toUserDTO(res)
	// 					if (user.avatar) {
	// 						this.fileState.loadEntities([user.avatar.id])
	// 					}
	// 					this.ctx.patchState({
	// 						user,
	// 						isLoading: false
	// 					})
	// 				}),
	// 				mapToVoid()
	// 			)
	// 	)
	// }

	@DataAction()
	public loadUserProfile(@Payload('user') user: UserDTO) {
		return this.backendService.getUserProfile(user.id).pipe(
			tap((res) => {
				if (res.length) {
					this.authState.setUserProfiles(res)
				}
				if (!res.length) {
					this.createUserProfile(null)
				}
				this.patchState({
					messagingToken:
						!res.length || !res[0].messagingToken
							? null
							: res[0].messagingToken,
					userProfileId: !res.length || !res[0]._id ? null : res[0]._id
				})
				this.checkIfUserShouldSeeSpotlightPatients({
					...user,
					last_login_time: res[0]!.last_login_time,
					userProfileId: res[0]._id!
				})
			})
		)
	}

	@DataAction()
	public loadAllUsersProfile() {
		return this.backendService.getAllUsersProfile().pipe(
			tap((res) => {
				this.patchState({
					allUsersProfile: res as unknown as UserInterface[]
				})
			})
		)
	}

	@DataAction()
	public createUserProfile(
		@Payload('messagingToken') messagingToken: string | null
	) {
		const user = this.getState().user
		return (
			this.backendService
				// @ts-ignore
				.addUserProfile(user, messagingToken)
				.pipe(
					tap((userProfileId) => {
						this.patchState({
							messagingToken,
							userProfileId
						})
					})
				)
		)
	}

	@DataAction()
	public updateUserProfile(
		@Payload('messagingToken') messagingToken: string | null
	): Observable<void> {
		const userProfileId = this.getState().userProfileId
		if (!userProfileId) return of()
		return this.backendService
			.setUserProfileMessagingToken(userProfileId as string, messagingToken)
			.pipe(
				tap((userProfileId) => {
					this.patchState({
						messagingToken,
						userProfileId: userProfileId
					})
				}),
				ignoreElements()
			)
	}

	@DataAction()
	canLoadTabletSpotlightPatients() {
		return
	}

	private checkIfUserShouldSeeSpotlightPatients(
		user: UserDTO & {
			last_login_time?: string
			userProfileId: string
		}
	): void {
		const lastUserLoginTime = user.last_login_time
		const isLastLoginTimeValid = moment(lastUserLoginTime).isValid()
		const isFirstShiftLogin =
			isLastLoginTimeValid && isBeforeCurrentShift(lastUserLoginTime!)
		if (
			(!lastUserLoginTime || isFirstShiftLogin || !isLastLoginTimeValid) &&
			(user._degree === 'MEDICAL_DOCTOR' ||
				user._degree === 'REGISTERED_NURSE') &&
			this.isMobile
		) {
			this.canLoadTabletSpotlightPatients()
			const newLoginTime = new Date().toISOString()
			this.updateUserLastLoginTime(newLoginTime, user.userProfileId)
				.pipe(take(1))
				.subscribe()
		}
	}

	private updateUserLastLoginTime(
		lastLoginTime: string,
		userProfileId: string
	) {
		return this.backendService
			.updateUserLastLoginTime(lastLoginTime, userProfileId)
			.pipe(
				catchError((er) => {
					return EMPTY
				})
			)
	}

	// @DataAction()
	// public deleteUserProfile(): Observable<void> {
	// 	const userProfileId = this.getState().userProfileId
	// 	if (!userProfileId) return of()
	// 	return this.backendService.deleteUserProfile(userProfileId).pipe(
	// 		tap(() => {
	// 			this.patchState({
	// 				messagingToken: null,
	// 				userProfileId: null
	// 			})
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	private initProductFruits(user: UserDTO): void {
		;(window as any).productFruitsReady = function () {
			;(window as any).productFruits.api.analytics.forwardEvent(
				(event: string, data: unknown) => {
					if (event === 'feedback_sent') {
						LogRocket.captureMessage(
							'Feedback sent ' + new Date().toISOString(),
							{
								tags: {
									event,
									userReport: 'user-report',
									data: JSON.stringify(data)
								}
							}
						)
					}
				}
			)
		}
		productFruits.init(
			this.isMobile
				? environment.productFruitsTabletCode
				: environment.productFruitsCode,
			'en',
			{
				username: user.name.firstName + ' ' + user.name.lastName,
				email: user.email
			}
		)
	}
}
