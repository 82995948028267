import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
	EMPTY,
	ignoreElements,
	interval,
	merge,
	Observable,
	Subscription,
	tap
} from 'rxjs'
import {
	LIMIT,
	NotificationBackend,
	NotificationDTO,
	NotificationFilter,
	NotificationInfo,
	NotificationInterface,
	NotificationQueryParams,
	NotificationSort
} from '../../shared/model/norification.model'
import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { UserState } from '../user/user.state'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BackendService } from '../../shared/services/backend.service'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { StoreEventsService } from '../store-events.service'
import { DepartmentState } from '../department/department.state'
import { NotificationService } from '../../shared/services/notification.service'
import { isEqual } from 'lodash-es'
import { PreferenceState } from '../preference/preference.state'

export const notificationFeatureName = 'notification'

@StateRepository()
@State<NotificationInterface>({
	name: notificationFeatureName,
	defaults: {
		data: [] as NotificationDTO[],
		isLoading: true,
		status: {
			read: 0,
			unread: 0,
			total: 0
		},
		totalCount: 0,
		currentQueryParams: null
	}
})
@Injectable()
export class NotificationState extends NgxsDataRepository<NotificationInterface> {
	private initialPollingSubscription: Subscription
	private pagePollingSubscription: Subscription
	private readonly isMobile = this.preferenceState.isMobile

	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private userState: UserState,
		private notification: NzNotificationService,
		private backendService: BackendService,
		private storeEvents: StoreEventsService,
		private departmentState: DepartmentState,
		private preferenceState: PreferenceState,
		private ntfService: NotificationService
	) {
		super()
	}

	@Selector()
	public static isLoading(state: NotificationInterface): boolean {
		return state.isLoading
	}

	@Selector()
	public static totalCount(state: NotificationInterface): number {
		return state.totalCount
	}

	@Selector()
	public static unReadStatus(state: NotificationInterface): number {
		return state.status.unread
	}

	@Selector()
	public static status(state: NotificationInterface): NotificationInfo | null {
		return state.status
	}

	@Selector()
	public static notifications(state: NotificationInterface): NotificationDTO[] {
		return state.data
	}

	public override ngxsOnInit() {
		if (this.isMobile) {
			return
		}
		merge(
			this.storeEvents.departmentChange$,
			this.storeEvents.departmentModifiedAndRefreshToken$
		)
			.pipe(
				tap(() => {
					this.stopInitialPolling()
					this.getNotificationsStatus()
					this.starInitialPolling()
				})
			)
			.subscribe()
		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.unsubscribeAll()
					this.reset()
				})
			)
			.subscribe()
	}

	starInitialPolling() {
		this.initialPollingSubscription = interval(60000).subscribe(() => {
			const queryParams = this.getState().currentQueryParams
			if (!queryParams || queryParams?.page === 0) {
				this.getNotificationsStatus()
			}
		})
	}

	clear() {
		this.patchState({
			isLoading: true,
			data: [],
			currentQueryParams: null,
			totalCount: 0
		})
	}

	stopInitialPolling() {
		if (this.initialPollingSubscription) {
			this.initialPollingSubscription.unsubscribe()
		}
	}

	startPagePolling() {
		this.pagePollingSubscription = interval(60000).subscribe(() => {
			const queryParams = this.getState().currentQueryParams
			if (!queryParams || queryParams?.page !== 0) {
				return
			}
			return this.getNotification(
				queryParams?.page as number,
				queryParams?.freeTextSearch,
				queryParams?.sort,
				queryParams?.filters
			)
		})
	}

	stopPagePolling() {
		if (this.pagePollingSubscription) {
			this.pagePollingSubscription.unsubscribe()
		}
	}

	unsubscribeAll() {
		this.stopInitialPolling()
		this.stopPagePolling()
	}

	setLoading(isLoading: boolean) {
		this.patchState({
			isLoading
		})
	}

	@DataAction()
	public getNotification(
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch?: string,
		@Payload('sort') sort?: NotificationSort[],
		@Payload('filters') filters?: NotificationFilter[]
	) {
		this.setLoading(true)
		const queryParams: NotificationQueryParams = {
			limit: LIMIT,
			page
		}

		if (freeTextSearch) queryParams.freeTextSearch = freeTextSearch
		if (sort && sort.length) queryParams.sort = sort
		if (filters && filters.length) queryParams.filters = filters
		this.patchState({ currentQueryParams: queryParams })

		return this.backendService.getNotifications(queryParams).pipe(
			tap((res: NotificationBackend) => {
				if (page === 0)
					this.patchState({
						data: [] as NotificationDTO[]
					})
				const notifications = Object.values(this.getState().data)
				this.patchState({
					data: [...notifications, ...res.data],
					totalCount: res.metadata.page?.totalResults
				})
				this.setLoading(false)
			})
		)
	}

	@DataAction()
	public markNotificationRead(@Payload('id') id: string) {
		return this.backendService.markNotificationRead(id).pipe(
			tap(() => this.getNotificationsStatus()),
			ignoreElements()
		)
	}

	@DataAction()
	public deleteNotification(
		@Payload('id') id: string,
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch?: string,
		@Payload('sort') sort?: NotificationSort[],
		@Payload('filters') filters?: NotificationFilter[]
	) {
		return this.backendService.deleteNotification(id).pipe(
			tap((res: NotificationBackend) => {
				this.ntfService.success(`Notification has been deleted.`)
				this.getNotification(page, freeTextSearch, sort, filters)
				this.getNotificationsStatus()
			})
		)
	}

	@DataAction()
	public getNotificationsStatus() {
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment
		return this.backendService.getNotificationsStatus(department?.id!).pipe(
			tap((res: NotificationInfo) => {
				if (isEqual(this.getState().status, res)) return
				this.patchState({ status: res })
			})
		)
	}

	@DataAction()
	public requestPermission(): void {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('./firebase-messaging-sw.js')
				.then(() => {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							this.getMessageToken()
						} else if (result === 'denied') {
							this.userState.updateUserProfile(null)
						}
					})
				})
				.catch(function (err) {
					console.log('Service worker registration failed, error:', err)
				})
		}
	}

	@DataAction()
	public receiveMessage() {
		this.angularFireMessaging.onMessage(({ data, notification }) => {
			new Notification(notification.title, {
				body: notification.body
			})
			// n.onclick = () => this.router.navigateByUrl(`patient/${data.patientId}`)
			this.notification.create(
				'warning',
				`Alert ${notification.title}`,
				notification.body,
				{
					nzDuration: 0,
					nzPauseOnHover: false
				}
			)
		})
	}

	getMessageToken(): void {
		const messagingToken = this.userState.getState().messagingToken
		this.angularFireMessaging.getToken.subscribe((token) => {
			if (!token || (messagingToken && messagingToken === token)) {
				return
			} else if (!messagingToken && this.userState.snapshot.userProfileId) {
				this.userState.updateUserProfile(token)
			} else if (messagingToken && this.userState.snapshot.userProfileId) {
				this.userState.updateUserProfile(token)
				return
			} else if (!this.userState.snapshot.userProfileId) {
				this.userState.createUserProfile(token)
			}
		})
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	protected setPaginationSetting(type: string | undefined): Observable<void> {
		return EMPTY
	}
}
