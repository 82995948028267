import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/en'
import { FormsModule } from '@angular/forms'
import {
	HTTP_INTERCEPTORS,
	HttpBackend,
	HttpClientModule
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BiotModule } from '../shared/modules/biot/biot.module'
import { HeadersModule } from './layouts/headers/headers.module'
import { TokenInterceptor } from '../core/interceprors/token.interceptor'
import { RequestInterceptor } from '../core/interceprors/request.interceptor'
import { MenuModule } from './layouts/menu/menu.module'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { RootStoreModule } from '../store/root-store.module'
import { PersistenceService } from '../shared/services/persistence.service'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import * as AllIcons from '@ant-design/icons-angular/icons'
import { IconDefinition } from '@ant-design/icons-angular'
import { NZ_ICONS, NzIconDirective } from 'ng-zorro-antd/icon'
import { IconService } from '../shared/services/icon.service'
import { SpinnersModule } from './layouts/UI/spinners/spinners.module'
import { MomentModule } from 'ngx-moment'
import { NgxEchartsModule } from 'ngx-echarts'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { AngularFireModule } from '@angular/fire/compat'
import { environment } from '../environments/environment'
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { HttpLoaderFactory } from '../core/helpers/translate-http-loader'
import { CoreModule } from '../core/core.module'
import { NzMessageService } from 'ng-zorro-antd/message'
// import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input'
import { IonicModule } from '@ionic/angular'
import { MobileIonicComponentsModule } from './layouts/mobile-ionic-components/mobile-ionic-components.module'
import { ModalsModule } from './layouts/modals/modals.module'
import { provideLottieOptions } from 'ngx-lottie'
import { ServiceWorkerModule } from '@angular/service-worker'
import { ReportLoaderComponent } from './pages/reports/components/report-loader/report-loader.component'
import { ScreenSaverModalComponent } from './layouts/modals/components/screen-saver-modal/screen-saver-modal.component'
import { CompareTimesInterceptor } from '../core/interceprors/compare-times.interceptor'
import { ClockUpdateModalComponent } from './layouts/modals/components/clock-update-modal/clock-update-modal.component'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import {
	NzInputDirective,
	NzInputGroupComponent,
	NzInputGroupWhitSuffixOrPrefixDirective
} from 'ng-zorro-antd/input'
import { ChargeNotifierDirective } from '../core/directives/charge-notifier.directive'

registerLocaleData(en)

export function playerFactory() {
	// add this line
	return import('lottie-web') // add this line
} // a
export function StartUpIconServiceFactory(iconService: IconService) {
	return () => iconService.loadSvgIcon()
}

const antDesignIcons = AllIcons as {
	[key: string]: IconDefinition
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
	(key) => antDesignIcons[key]
)

@NgModule({
	declarations: [AppComponent],
	imports: [
		// NgxIntlTelephoneInputModule,
		RootStoreModule,
		BiotModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		HeadersModule,
		MenuModule,
		NzLayoutModule,
		SpinnersModule,
		MomentModule,
		ReportLoaderComponent,
		IonicModule.forRoot({
			mode: 'md'
		}),
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAnalyticsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpBackend]
			}
		}),
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		CoreModule,
		MobileIonicComponentsModule,
		ModalsModule,
		ServiceWorkerModule.register('./sw.js', {
			enabled: window.location.hostname !== 'localhost',
			registrationStrategy: 'registerImmediately'
		}),
		ScreenSaverModalComponent,
		ClockUpdateModalComponent,
		NzCollapseModule,
		NzIconDirective,
		NzInputDirective,
		NzInputGroupComponent,
		NzInputGroupWhitSuffixOrPrefixDirective,
		ChargeNotifierDirective
	],
	providers: [
		provideLottieOptions({
			player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web')
		}),
		PersistenceService,
		NzNotificationService,
		NzMessageService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		{ provide: NZ_I18N, useValue: en_US },
		{ provide: NZ_ICONS, useValue: icons },
		{
			provide: APP_INITIALIZER,
			useFactory: StartUpIconServiceFactory,
			deps: [IconService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CompareTimesInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
