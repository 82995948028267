import { NewReadSocketModel } from './new-read-socket-model'
import { WarningDescription, WarningTitle } from '../constants/warning.constant'
import { ObservationFields, PatientObservationDTO } from './patient-observation'
import { AlertRuleDTO } from './alert-rules.model'
import { AlertSubject } from './alert'
import { PccUpdateEmrMeasurement } from './pcc.model'
import { PatientBPCuffSize, PatientVitalsTakenGroup } from './patient'

export interface ReportInterface {
	requestPatientsVitals: string[]
	requestVitals: string[]
	monitorPatientIds: string[]
	textFilter: string
	type: ReportType
	mode: ModeType
	loading: boolean
	isMeasurementSending: boolean
	isAbnormalMissingFilter: boolean
	forceReadDevicesStatus: NewReadSocketModel[]
	isForceReadProcess: boolean
	currentShift: string | null
}

export interface ManualPccVitalsInterface {
	observedPatient: string
	emrId: number
	bodyTemperature?: number
	spo2?: number
	diastolicPressure?: number
	heartRate?: number
	respirationRate?: number
	systolicPressure?: number

	[key: string]: any
}

export type VitalsFieldsKeys = keyof typeof VitalsFields

export interface PccVitalsUpdateEmrMethodInterface
	extends ManualPccVitalsInterface {
	bloodPressureMethod?: string
	heartRateMethod?: string
	spo2Method?: string
	bodyTemperatureMethod?: string
}

export interface PatientVitalsInterface {
	id: string
	avatarUrl?: string
	firstName: string
	lastName: string
	name: string
	// TODO: Here is good example of using enum instead of string
	emrStatus: VitalStatusIndicator | null
	// TODO: We should prefer using Date to string. It allows to perform safe Date manipulations and logic. The best place is to convert string to Date is on network boundary,
	//  but maybe currently it is too large of a change. This interface is currently visual only so less logic here and pipes we use to format handle strings.
	//  Anyway, we could start with this interface as an experiment
	lastEMRUpdateTime: string | undefined | null
	room: string
	vitals: PatientVItalObservationFields
	insights: PatientVItalInsightsObservationFields
	//TODO: You already has enum for that DeviceCriticalStatus.
	// In general we should prefer using enums and perform some kind of "translation" to human labels
	statusDetailInformation: string
	lastMeasurementTime: Date | null | string
	isActualLastMeasurementTime: boolean
	isOldMeasurementTime: boolean
	checkShiftSentEmrInformation: CheckShiftSentEmrInformationInterface | null
	monitored: boolean
	cuffSize: PatientBPCuffSize | null
	checked: boolean
	wasSentToEMR: boolean
	sentToEMR: boolean
	forceReadLoading: NewReadSocketModel | null | any
	measurement: PatientObservationDTO | null
	patientAlertRules: AlertRuleDTO | null
	defaultAlertRules: AlertRuleDTO | null
	emrid: number | null | undefined
	isVitalComplete: boolean
	vitalStatusIcon: string
	enabled: boolean
	vitalsTakenGroup?: PatientVitalsTakenGroup
	requiredVitals: Partial<{ [key in ObservationFields]: boolean }>
	hasSomeVitalsTaken: boolean
}

type DynamicPatientVitalObservationFields = {
	[key in VitalsFields]?: PatientVitalsItem | null
}

export type PatientVItalObservationFields = DynamicPatientVitalObservationFields

export interface PatientVItalInsightsObservationFields {
	systolicPressure: PatientVitalInsight | null
	diastolicPressure: PatientVitalInsight | null
	heartRate: PatientVitalInsight | null
	respirationRate: PatientVitalInsight | null
	spo2: PatientVitalInsight | null
	bodyTemperature: PatientVitalInsight | null
}

export interface EmrRule {
	min: number
	max: number
}

export enum VitalsFields {
	BodyTemperature = 'bodyTemperature',
	HeartRate = 'heartRate',
	RespirationRate = 'respirationRate',
	SpO2 = 'spo2',
	DiastolicPressure = 'diastolicPressure',
	SystolicPressure = 'systolicPressure',
	BloodGlucose = 'bloodGlucose'
}

export enum ShiftCategory {
	VitalsRequired = 'VITALS_REQUIRED',
	VitalsNotRequired = 'VITALS_NOT_REQUIRED'
}

export interface PatientVitalsItem {
	value?: number | null | undefined
	isManual?: boolean
	//TODO: Align on Date only
	timestamp?: string | Date
	isCriticalVital?: boolean
	isOldVital?: boolean
	isActualValue?: boolean
	checked?: boolean
	isMissing?: boolean
}

export interface PatientVitalInsight {
	lastModifiedTime: Date | string
	outlineType: string
	insightSubject: AlertSubject
	insightDuration: string
}

export enum ReportType {
	Spot = 'spot',
	Time = 'time',
	CurrentShift = 'current_shift'
}

export enum ModeType {
	Latest = 'LATEST',
	Average = 'AVERAGE'
}

export enum ReportMode {
	Average = 'AVERAGE',
	Latest = 'LATEST',
	Recent_Abnormal = 'RECENT_ABNORMAL'
}

export enum VitalStatusIcon {
	Completed = 'icons:report-complete-export-emr',
	NotRequired = 'icons:report-emr',
	NeedExportToEmr = 'icons:report-need-export-emr',
	PartialExportEmr = 'icons:report-partial-export-emr'
}

export enum VitalStatusIndicator {
	Completed = 'indicator-complete',
	NeedExportToEmr = 'indicator-need-export',
	FailedExportToEmr = 'indicator-failed-emr',
	PartialExportEmr = 'indicator-partial-export-emr'
}

export enum SentEmrInformation {
	Completed = 'COMPLETED',
	NeedExportToEmr = 'NEED_EXPORT_TO_EMR',
	CriticalReportTime = 'CRITICAL_REPORT_TIME',
	FailedEmr = 'FAILED_EMR',
	NotRequired = 'NOT_REQUIRED',
	PartialExport = 'PARTIAL_EXPORT_TO_EMR'
}

export enum ReportVitalsFields {
	RepositionPatient = 'reposition_patient',
	ChangeDiaper = 'change_diaper',
	MeasureHeartRate = 'measure_heart_rate',
	MeasureBodyTemperature = 'measure_body_temperature',
	MeasureBloodPressure = 'measure_blood_pressure',
	MeasureBloodSugar = 'measure_blood_sugar',
	MeasureRespirationRate = 'measure_respiration_rate',
	MeasureSpo2 = 'measure_spo2',
	MeasureAllVitals = 'measure_all_vitals'
}

export interface ReportsVitalsInterface {
	patientActions: {
		patientId: string
		treatmentActions: string[]
	}[]
}

export interface CheckShiftSentEmrInformationInterface {
	bp?: boolean
	bpStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	bt?: boolean
	btStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	hr?: boolean
	hrStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	rr?: boolean
	rrStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	spo2?: boolean
	spo2Status?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	bg?: boolean
	bgStatus?: 'SENT_TO_EMR' | 'NEED_SEND_TO_EMR'
	hasSomeVitals?: boolean
	category?: ShiftCategory
	status:
		| 'COMPLETED'
		| 'NEED_EXPORT_TO_EMR'
		| 'CRITICAL_REPORT_TIME'
		| 'FAILED_EMR'
		| 'NOT_REQUIRED'
		| 'PARTIAL_EXPORT_TO_EMR'
}

export enum VitalStatus {
	SentToEmr = 'SENT_TO_EMR',
	NeedSendToEmr = 'NEED_SEND_TO_EMR'
}

export type OriginalDeviceValues = {
	diastolicSystolic:
		| undefined
		| {
				diastolic: number | undefined
				systolic: number | undefined
		  }
	heartRate: number | undefined
	respirationRate: number | undefined
	spo2: number | undefined
	bodyTemperature: number | undefined
	bloodGlucose?: number | undefined
}

export interface ManualVitalsInterface extends PccUpdateEmrMeasurement {
	bodyTemperatureManual?: boolean
	bodyTemperatureDeviceId?: string
	pressureManual?: boolean
	pressureDeviceId?: string
	heartRateManual?: boolean
	heartRateDeviceId?: string
	respirationRateManual?: boolean
	respirationRateDeviceId?: string
	spo2Manual?: boolean
	spo2DeviceId?: string
	bloodGlucoseManual?: boolean
	bloodGlucoseDeviceId?: string
	title?: WarningTitle
	description?: WarningDescription
	deviceId?: string
	changedDatabodyTemperature?: boolean
	changedDatadiastolicPressure?: boolean
	changedDatasystolicPressure?: boolean
	changedDatabloodGlucose?: boolean
	changedDataspo2?: boolean
	changedDatarespirationRate?: boolean
	changedDataheartRate?: boolean
	originalDeviceValues?: OriginalDeviceValues
}