import {
	afterNextRender,
	DestroyRef,
	Directive,
	inject,
	output,
	Signal
} from '@angular/core'
import { Select } from '@ngxs/store'
import { DeviceState } from '../../../store/device/device.state'
import {
	concatAll,
	distinct,
	filter,
	map,
	merge,
	Observable,
	skipUntil,
	Subscription,
	timer
} from 'rxjs'
import { DeviceDTO, DeviceNames } from '../../../shared/model/device.model'
import { MeasurementState } from '../../../store/measurement/measurement.state'
import { PreferenceState } from '../../../store/preference/preference.state'
import { isNotNil } from '@angular-ru/cdk/utils'
import { toSignal } from '@angular/core/rxjs-interop'

@Directive({
	selector: '[aiomedReceivingData]',
	standalone: true
})
export class ReceivingDataDirective {
	@Select(DeviceState.currentUserDevice)
	currentUserDevice$: Observable<DeviceDTO>
	@Select(DeviceState.sharedDevices)
	sharedDevices$: Observable<DeviceDTO[] | undefined>
	@Select(PreferenceState.secondaryDevicesIds)
	secondaryDevicesIds$: Observable<string[] | null>
	deviceUpdatesEmitter = output<boolean>()
	private measurementState = inject(MeasurementState)
	private currentUserDevice: Signal<DeviceDTO>
	private secondaryDevicesIds: Signal<string[] | null>
	private destroyRef = inject(DestroyRef)
	private deviceUpdatingSubscription: Subscription

	constructor() {
		this.currentUserDevice = toSignal(this.currentUserDevice$, {
			initialValue: {} as DeviceDTO
		})
		this.secondaryDevicesIds = toSignal(this.secondaryDevicesIds$, {
			initialValue: []
		})

		afterNextRender(() => this.setCurrentDeviceFieldsUpdates())
		this.destroyRef.onDestroy(() => {
			if (this.deviceUpdatingSubscription) {
				this.deviceUpdatingSubscription.unsubscribe()
			}
		})
	}

	private setCurrentDeviceFieldsUpdates(): void {
		this.deviceUpdatingSubscription = merge(
			this.sharedDevices$,
			this.measurementState.localDeviceMeasurementsMessage$.pipe(
				map((device) => [device])
			)
		)
			.pipe(
				filter(isNotNil),
				concatAll(),
				distinct((d) => d.id + d.status.last_update_time),
				skipUntil(timer(1000))
			)
			.subscribe((device) => {
				if (
					device.id !== DeviceNames.LOCAL &&
					device.id !== this.currentUserDevice().id &&
					!this.secondaryDevicesIds()?.includes(device.id)
				) {
					return
				}
				this.deviceUpdatesEmitter.emit(true)
			})
	}
}
